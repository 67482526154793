import { effect, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { envToken, IEnvironment } from '@core/services/environment';
import { Country, i18nServiceToken, II18nService, LanguageCode } from '@core/services/i18n';
import { LOADING_STATUS, LoadingStatus } from '@core/types/loading-status';
import { getLogger, ILogger } from '@core/utilities/logger';
import { IGoogleMapsApiService } from './google-maps-api.service.domain';

declare global {
    interface Window {
        __gjsload__: () => void;
    }
}

enum GoogleMapsVersion {
    Beta = 'beta',
    Alpha = 'alpha',
    Weekly = 'weekly',
    Quarterly = 'quarterly',
    Number = '3.58.3',
}

interface IGoogleMapsQuery {
    callback?: string;

    libraries: string;

    language?: LanguageCode;

    region?: Country;

    loading: string;

    key: string;

    v?: string;
}

@Injectable({providedIn: 'root'})
export class GoogleMapsApiService implements IGoogleMapsApiService {
    private logger: ILogger = getLogger('GoogleMapsApiService');

    private readonly version: string = GoogleMapsVersion.Weekly;

    // TODO: LOAD FROM ENVIRONMENT
    private readonly apiUrl: string = 'https://maps.googleapis.com/maps/api/js';

    private country: Country = this.i18nService.country;

    private language: Signal<LanguageCode> = this.i18nService.currentLanguage;

    public loadingStatus: WritableSignal<LoadingStatus> = signal(LOADING_STATUS.DEFAULT);

    constructor(
        @Inject(envToken) private env: IEnvironment,
        @Inject(i18nServiceToken) private i18nService: II18nService,
    ) {
        effect(() => this.loadMapsApi(this.language()), {allowSignalWrites: true});
    }

    private loadMapsApi(language: LanguageCode): Promise<void> {
        window.__gjsload__ = (): void => this.onMapsApiLoaded();

        this.unloadMapsApi();

        this.loadingStatus.set(LOADING_STATUS.LOADING);

        return new Promise(() => {
            const googleMapsQuery = this.getMapsApiQuery(language);
            const params: URLSearchParams = new URLSearchParams(Object.entries(googleMapsQuery));
            const script = this.createScript(`${ this.apiUrl }?${ params }`);

            this.logger.log('loadMapsApi(): method: GET, api: {0}, params: {1}', [this.apiUrl, googleMapsQuery]);

            document.head.append(script);
        });
    }

    private unloadMapsApi(): void {
        const links: NodeListOf<HTMLLinkElement> = document.querySelectorAll('link[href*="fonts.googleapis.com"]');
        const scripts: NodeListOf<HTMLScriptElement> = document.querySelectorAll('script[src*="maps.googleapis.com"]');

        if (links.length) {
            links.forEach((link: HTMLLinkElement) => {
                this.logger.log('unloadMapsApi(): removed font {0}', [link.href]);
                link.remove();
            });
        }

        if (scripts.length) {
            scripts.forEach((script: HTMLScriptElement) => {
                this.logger.log('unloadMapsApi(): removed script {0}', [script.src]);
                script.remove();
            });
        }

        if (window.google && window.google.maps) {
            window.google.maps = {} as typeof google.maps;
        }
    }

    private createScript(src: string): HTMLScriptElement {
        const script: HTMLScriptElement = document.createElement('script');

        script.type = 'text/javascript';
        script.async = true;
        script.src = src;
        script.onerror = this.onMapsApiError;

        return script;
    }

    private getMapsApiQuery(language: LanguageCode): IGoogleMapsQuery {
        return {
            language,
            v: this.version,
            key: this.env.google.key,
            region: this.country,
            loading: 'async',
            callback: 'window.__gjsload__',
            libraries: 'places',
        };
    }

    private onMapsApiLoaded = (): void => {
        this.logger.log(
            'onMapsApiLoaded(): language: {0}, version: {1}, version update: {2}',
            [this.language(), window.google.maps.version, this.version],
        );
        this.loadingStatus.set(LOADING_STATUS.LOADED);
    };

    private onMapsApiError = (): void => {
        this.logger.error('onMapsApiError(): error loading script {0}', [this.apiUrl]);
        this.loadingStatus.set(LOADING_STATUS.ERROR);
    };
}
