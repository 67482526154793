import { Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { IDialogService, LazyLoadComponent } from './dialog.service.domain';

@Injectable({providedIn: 'root'})
export class DialogService implements IDialogService {

    constructor(private dialog: Dialog, private overlay: Overlay) {
    }

    public showModal<R, D>(lazyLoadComponent: LazyLoadComponent, data: D, afterClosedCallback?: (data?: R) => void): (result?: R) => void {
        let dialogRef: DialogRef<R>;

        lazyLoadComponent().then((component: ComponentType<unknown>) => {
            dialogRef = this.dialog.open<R, D>(component, {
                data,
                restoreFocus: true,
            });

            // TODO: ADD LOGGER MESSAGE OPENED AND SUBJECT

            dialogRef.closed.subscribe((result: R | undefined) => {
                // TODO: ADD LOGGER MESSAGE
                if (afterClosedCallback) {
                    afterClosedCallback(result);
                }
            });
        });

        return (result?: R) => {
            dialogRef.close(result);
        };
    }

    public showPopup<R, D>(
        elementRef: HTMLElement,
        lazyLoadComponent: LazyLoadComponent,
        data: D,
        afterClosedCallback?: (data?: R) => void,
    ): (result?: R) => void {
        let dialogRef: DialogRef<R>;

        const positionStrategy = this.overlay.position()
            .flexibleConnectedTo(elementRef)
            .withPush(false)
            .withViewportMargin(24)
            .withPositions([
                {
                    originX: 'end',
                    originY: 'bottom',
                    overlayX: 'end',
                    overlayY: 'top',
                    offsetY: 6,
                },
            ]);

        const config: DialogConfig<D, DialogRef<R>> = {
            data,
            restoreFocus: true,
            positionStrategy,
        };

        lazyLoadComponent().then((component: ComponentType<unknown>) => {
            dialogRef = this.dialog.open<R, D>(component, config);

            // TODO: ADD LOGGER MESSAGE OPENED AND SUBJECT

            dialogRef.closed.subscribe((result: R | undefined) => {
                // TODO: ADD LOGGER MESSAGE
                if (afterClosedCallback) {
                    afterClosedCallback(result);
                }

            });
        });

        return (result?: R) => {
            dialogRef.close(result);
        };
    }
}
