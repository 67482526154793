import { INewsDto } from '@core/dto/news';
import { INews } from './news.entity';

export class News implements INews {
    public id: string;

    public title: string;

    public image: string;

    public message: string;

    constructor(dto: INewsDto) {
        this.id = dto.id || '';
        this.title = dto.h1 || '';
        this.image = dto.rightImageSrc || '';
        this.message = dto.body || '';
    }
}
