import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { envToken, IEnvironment } from '@core/services/environment';

export const permissionsGuard: CanActivateFn = (_: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree | boolean => {
    const router: Router = inject(Router);
    const env: IEnvironment = inject(envToken);

    if (env.workspaceRestrictedTo.some((x: string) => state.url.startsWith(x, 1))) {
        return true;
    }

    return router.parseUrl(`/${ env.workspaceDefaultsTo }`);
};
