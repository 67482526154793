import { Routes } from '@angular/router';
import { permissionsGuard } from '@core/guards/permissions';
import { WorkspaceRoute } from '@core/types/workspace-route';

export const TEMPTON_CONNECT_ROUTES: Routes = [
    {
        path: '',
        canActivate: [permissionsGuard],
        children: [
            {
                path: WorkspaceRoute.Dispatcher,
                loadChildren: () => import('./workspace/dispatcher/dispatcher.routes').then((r) => r.DISPATCHER_ROUTES),
            },

            {
                path: WorkspaceRoute.Customer,
                loadChildren: () => import('./workspace/customer/customer.routes').then((r) => r.CUSTOMER_ROUTES),
            },

            {
                path: WorkspaceRoute.Prospect,
                loadChildren: () => import('./workspace/prospect/prospect.routes').then((r) => r.PROSPECT_ROUTES),
            },
            //
            // {
            //     path: WORKSPACE.MARKETPLACE,
            //     loadChildren: () => import('./pages/marketplace/marketplace.routes').then((r) => r.MARKETPLACE_ROUTES),
            // },
            //
            // {
            //     path: WORKSPACE.GUEST,
            //     loadChildren: () => import('./pages/guest/guest.routes').then((r) => r.GUEST_ROUTES),
            // },
        ],
    },

    // {
    //     path: 'auth',
    //     loadChildren: () => import('./pages/common/auth/auth.routes').then((r) => r.AUTH_ROUTES),
    // },

    // {
    //     path: 'nicht-erlaubt',
    //     loadComponent: () => import('./pages/common/forbidden/forbidden.page').then((c) => c.ForbiddenPage),
    // },

    {
        path: '**',
        redirectTo: '',
    },
];
