import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

@Injectable()
export class DateService extends NativeDateAdapter {
    public getFirstDayOfWeek(): number {
        return 1;
    }

    public format(date: Date, displayFormat: Intl.DateTimeFormatOptions): string {
        const locale: string = displayFormat.day ? 'de-DE' : this.locale as string;
        const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
            ...displayFormat,
            timeZone: 'utc',
        };

        if (!this.isValid(date)) {
            throw Error('DatetimeService: Cannot format invalid date.');
        }

        const dateTimeFormat: Intl.DateTimeFormat = new Intl.DateTimeFormat(locale, dateTimeFormatOptions);
        const newDate: Date = new Date();

        newDate.setUTCFullYear(date.getFullYear(), date.getMonth(), date.getDate());
        newDate.setUTCHours(date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds());

        return dateTimeFormat.format(newDate);
    }

    public toIso8601(date: Date): string {
        return [
            date.getUTCFullYear(),
            this._twoDigit(date.getUTCMonth() + 1),
            this._twoDigit(date.getDate()),
        ].join('-');
    }

    private _twoDigit(n: number): string {
        return ('00' + n.toString()).slice(-2);
    }
}
