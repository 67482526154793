import { Injectable, Provider } from '@angular/core';
import { getLogger, ILogger } from '@core/utilities/logger';
import { ILazyLoadScript, IScriptLoaderService, Script, scriptLoaderServiceToken, scripts } from './script-loader.provider';

@Injectable()
export class ScriptLoaderService implements IScriptLoaderService {
    private logger: ILogger = getLogger('ScriptLoader');

    private scripts: { [key: string]: ILazyLoadScript } = {};

    constructor() {
        scripts.forEach((script: ILazyLoadScript) => {
            this.scripts[Script[script.name]] = script;
        });
    }

    public load(lazyLoadingScript: Script, siteKey: string): Promise<ILazyLoadScript> {
        const name: string = Script[lazyLoadingScript];

        this.logger.log('load(): loading {0} ...', [name]);

        return new Promise((resolve: (data: ILazyLoadScript) => void) => {
            if (this.scriptLoaded(lazyLoadingScript)) {
                return resolve(this.onLoad(name));
            }

            const script: HTMLScriptElement = document.createElement('script');

            script.type = 'text/javascript';
            script.async = true;
            script.src = this.scripts[name].src + siteKey;

            script.onload = (): void => resolve(this.onLoad(name));

            script.onerror = (e: string | Event): void => resolve(this.onError(name, e));

            window.document.head.appendChild(script);
        });
    }

    public scriptLoaded(name: Script): boolean {
        const script: ILazyLoadScript = this.scripts[Script[name]];

        return script && script.loaded;
    }

    private onLoad(name: string): ILazyLoadScript {
        this.scripts[name].loaded = true;

        this.logger.log('onLoad(): {0} loaded successfully', [name]);

        return this.scripts[name];
    }

    private onError(name: string, e: string | Event): ILazyLoadScript {
        this.scripts[name].loaded = false;

        this.logger.error('onError(): {0}, error {1}', [name, e]);

        return this.scripts[name];
    }
}

export const SCRIPT_LOADER_SERVICE_PROVIDER: Provider = {
    provide: scriptLoaderServiceToken,
    useClass: ScriptLoaderService,
};
