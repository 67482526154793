import { Provider } from '@angular/core';
import { APP_STORAGE_SERVICE_PROVIDER } from './app-storage/app-storage.service.provider';
import { CANDIDATE_PHOTO_SERVICE_PROVIDER } from './candidate-photo';
import { DATE_SERVICE_PROVIDER } from './date/date.service.provider';
import { DIALOG_SERVICE_PROVIDER } from './dialog/dialog.service.provider';
import { GOOGLE_MAPS_API_SERVICE_PROVIDER } from './google/maps-api/google-maps-api.service.provider';
import { GOOGLE_PLACES_SERVICE_PROVIDER } from './google/places/google-places.service.provider';
import { I18N_SERVICE_PROVIDER } from './i18n/i18n.service.provider';
import { APP_INIT_SERVICE_PROVIDER } from './initializer';
import { NEWS_SERVICE_PROVIDER } from './news/news.service.provider';
import { QUALIFICATIONS_SERVICE_PROVIDER } from './qualification/qualification.service.provider';
import { SCRIPT_LOADER_SERVICE_PROVIDER } from './script-loader';
import { SETTINGS_SERVICE_PROVIDER } from './settings';

export const CORE_PROVIDERS: Provider[] = [
    APP_INIT_SERVICE_PROVIDER,
    APP_STORAGE_SERVICE_PROVIDER,
    CANDIDATE_PHOTO_SERVICE_PROVIDER,
    DATE_SERVICE_PROVIDER,
    DIALOG_SERVICE_PROVIDER,
    GOOGLE_MAPS_API_SERVICE_PROVIDER,
    GOOGLE_PLACES_SERVICE_PROVIDER,
    I18N_SERVICE_PROVIDER,
    NEWS_SERVICE_PROVIDER,
    QUALIFICATIONS_SERVICE_PROVIDER,
    SCRIPT_LOADER_SERVICE_PROVIDER,
    SETTINGS_SERVICE_PROVIDER,

];
