import { InjectionToken } from '@angular/core';

export const scriptLoaderServiceToken: InjectionToken<IScriptLoaderService> = new InjectionToken<IScriptLoaderService>(
    'scriptLoaderServiceToken');

export enum Script {
    GTM,
    RE_CAPTCHA,
}

export interface ILazyLoadScript {
    name: Script;

    loaded: boolean;

    src: string;
}

export const scripts: ILazyLoadScript[] = [
    {
        name: Script.GTM,
        src: 'https://www.googletagmanager.com/gtag/js?id=',
        loaded: false,
    },
    {
        name: Script.RE_CAPTCHA,
        src: 'https://www.google.com/recaptcha/api.js?hl=de&render=',
        loaded: false,
    },
];

export interface IScriptLoaderService {
    load(script: Script, siteKey: string): Promise<ILazyLoadScript>;

    scriptLoaded(url: Script): boolean;
}
