import { Injectable } from '@angular/core';
import { getLogger, ILogger } from '@core/utilities/logger';
import { IAppStorageProperties, IAppStorageService } from './app-storage.service.domain';

const APPLICATION_PROPERTIES: string = 'applicationProperties';

const DEFAULT_APP_STORAGE_PROPERTIES: IAppStorageProperties = {
    language: '',
    allowSkipWatchlistProfile: false,
    allowSkipWatchlistCreation: false,
};

@Injectable({providedIn: 'root'})
export class AppStorageService implements IAppStorageService {

    private logger: ILogger = getLogger('AppStorageService');

    public properties: IAppStorageProperties = this.getAppStorage();

    public getLanguage(): string {
        return this.properties.language;
    }

    public setLanguage(language: string): void {
        this.properties = {...this.properties, language};
        this.validateAppStorage();
    }

    public getAllowSkipWatchlistProfile(): boolean {
        return this.properties.allowSkipWatchlistProfile;
    }

    public setAllowSkipWatchlistProfile(allowSkipWatchlistProfile: boolean): void {
        this.properties = {...this.properties, allowSkipWatchlistProfile};
        this.validateAppStorage();
    }

    public getAllowSkipWatchlistCreation(): boolean {
        return this.properties.allowSkipWatchlistCreation;
    }

    public setAllowSkipWatchlistCreation(allowSkipWatchlistCreation: boolean): void {
        this.properties = {...this.properties, allowSkipWatchlistCreation};
        this.validateAppStorage();
    }

    private validateAppStorage(): void {
        const value: string = JSON.stringify(this.properties);
        this.logger.log('validateAppStorage: {0} {1}', [APPLICATION_PROPERTIES, this.properties]);
        window.localStorage.setItem(APPLICATION_PROPERTIES, value);
    }

    private getAppStorage(): IAppStorageProperties {
        const value: string | null = window.localStorage.getItem(APPLICATION_PROPERTIES);
        const storage: IAppStorageProperties = value ? JSON.parse(value) as IAppStorageProperties : DEFAULT_APP_STORAGE_PROPERTIES;

        this.logger.log('getAppStorage: {0} {1}', [APPLICATION_PROPERTIES, storage]);

        return storage;
    }
}
