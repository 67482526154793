import { Inject, Injectable, Signal } from '@angular/core';
import { Country, i18nServiceToken, II18nService, LanguageCode } from '@core/services/i18n';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGooglePlacesService } from './google-places.service.domain';

@Injectable()
export class GooglePlacesService implements IGooglePlacesService {
    // private logger: ILogger = getLogger('GooglePlacesService');

    private country: Country = this.i18nService.country;

    private language: Signal<LanguageCode> = this.i18nService.currentLanguage;

    // TODO: CHECK LANGUAGE AND COUNTRY PARAMS
    private autocompleteRequest: Partial<google.maps.places.AutocompleteRequest> = {
        region: this.country,
        language: this.language(),
        includedPrimaryTypes: ['postal_code', 'locality', 'street_address', 'street_number', 'route'],
        includedRegionCodes: [this.country],
    };

    constructor(@Inject(i18nServiceToken) private i18nService: II18nService) {
    }

    public search(input: string): Observable<google.maps.places.AutocompleteSuggestion[]> {
        const autocompleteRequest = this.getAutocompleteRequest(input);

        // this.logger.log('fetchAutocompleteSuggestions: {0}', [autocompleteRequest]);

        return from(google.maps.places.AutocompleteSuggestion.fetchAutocompleteSuggestions(autocompleteRequest)).pipe(
            map(({suggestions}) => suggestions),
            // tap((data) => console.error(data)),
        );
    }

    private getAutocompleteRequest(input: string): google.maps.places.AutocompleteRequest {
        return {input, ...this.autocompleteRequest/*, sessionToken: new google.maps.places.AutocompleteSessionToken()*/};
    }

}
