import { HttpClient } from '@angular/common/http';
import { computed, inject, Inject, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Params } from '@angular/router';
import { INewsDto } from '@core/dto/news';
import { INews, News } from '@core/models/news';
import { envToken, IEnvironment } from '@core/services/environment';
import { LOADING_STATUS, LoadingStatus } from '@core/types/loading-status';
import { defaultPagination, IPagination } from '@core/types/pagination';
import { merge, Observable, of, shareReplay, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ISettingsService, settingsServiceToken } from '../settings';
import { INewsService } from './news.service.domain';

@Injectable({providedIn: 'root'})
export class NewsService implements INewsService {
    private http: HttpClient = inject(HttpClient);

    private NEWS_API: string = 'news';

    private news$: Observable<News[]> = this.getNewsList(this.env.news_channel).pipe(
        map((messages: INewsDto[]) => (messages || []).map((x: INewsDto) => new News(x))),
        shareReplay(),
    );

    public news: Signal<INews[]> = toSignal(this.news$, {initialValue: []});

    public readNewsIds: Signal<string[]> = computed(() => {
        return this.settingsService.settings().read_news || [];
    });

    public unReadNewsPagination: WritableSignal<IPagination> = signal<IPagination>(defaultPagination);

    private loadingStatusAction: Subject<LoadingStatus> = new Subject<LoadingStatus>();

    private loadingStatus$: Observable<LoadingStatus> = merge(
        this.loadingStatusAction.pipe(map(() => LOADING_STATUS.LOADING)),
        this.settingsService.settings$.pipe(map(() => LOADING_STATUS.LOADED)),
    );

    public loadingStatus: Signal<LoadingStatus> = toSignal(this.loadingStatus$, {initialValue: LOADING_STATUS.LOADING});

    constructor(
        @Inject(settingsServiceToken) private settingsService: ISettingsService,
        @Inject(envToken) private env: IEnvironment,
    ) {
    }

    public getNewsList(channel: string): Observable<INewsDto[]> {
        const body: Params = {channel_like: `/${ channel }/welcome`};

        return this.http.request<INewsDto[]>('GET', this.NEWS_API, {body})
            .pipe(
                map((messages?: INewsDto[]) => messages || []),
                catchError(() => of([])),
            );
    }

    public markAsRead(newsId: string): void {
        this.loadingStatusAction.next(LOADING_STATUS.LOADING);
        this.settingsService.updateSettings({
            read_news: [...this.readNewsIds(), newsId],
        });
    }

    public markAsUnRead(newsId: string): void {
        this.loadingStatusAction.next(LOADING_STATUS.LOADING);
        this.settingsService.updateSettings({
            read_news: this.readNewsIds().filter((id: string) => id !== newsId),
        });
    }
}
