import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { envToken, IEnvironment } from '@core/services/environment';
import { IBackendError } from '@core/types/backend-error';
import { Http } from '@core/utilities/http';
import { omitEmpty } from '@core/utilities/lang';
import { getLogger, ILogger } from '@core/utilities/logger';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class RequestParamsInterceptor implements HttpInterceptor {
    private env: IEnvironment = inject(envToken);

    private logger: ILogger = getLogger('ApiInterceptor');

    // TODO: LOG SUCCESS REQUEST

    public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        if (!request.url.startsWith(this.env.publicDir) && !/http[s]?:\/\//.test(request.url)) {
            return this.handleApiRequest(request, next)
                .pipe(
                    catchError((response: HttpErrorResponse) => {
                        return this.throwError(response, request);
                    }),
                );
        }

        return next.handle(request);
    }

    private handleApiRequest<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
        this.logRequest(request);

        return next.handle(request.clone({
            url: this.getRequestApiUrl(request),
            body: this.getRequestApiBody(request),
            withCredentials: false,
        }));
    }

    private getRequestApiUrl<T>(request: HttpRequest<T>): string {
        if (request.method === 'GET' && request.body) {
            return this.getApiUrl(request) + '?' + Http.query(request.body);
        }

        return this.getApiUrl(request);
    }

    private getRequestApiBody<T>(request: HttpRequest<T>): T | null {
        return request.method === 'GET' ? null : request.body;
    }

    private logRequest<T>(request: HttpRequest<T>): void {
        this.logger.log(
            `method: {0}, api: {1}, params: {2}`,
            [
                request.method,
                this.getApiUrl(request),
                this.getLogParams(request.method, request.body),
            ],
        );
    }

    private getLogParams<T>(method: string, body: T | null): unknown {
        return method === 'GET' && body ? omitEmpty(body) : body;
    }

    private getApiUrl<T>(request: HttpRequest<T>): string {
        return this.env.apiUrl + '/' + request.url;
    }

    private throwError<T>(response: HttpErrorResponse, request: HttpRequest<T>): Observable<never> {
        // TODO: STOP LOADING SPINNER HERE !!!
        const error: IBackendError = Http.getError(response);

        this.logger.error(
            `api url: {0}, method: {1}, code: {2}, message: {3}, reason: {4}`,
            [
                this.env.apiUrl + '/' + request.url,
                request.method,
                error.code,
                error.message,
                error.reason,
            ],
        );

        return throwError(() => error);
    }
}
