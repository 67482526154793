import { HttpErrorResponse } from '@angular/common/http';
import { IBackendError } from '@core/types/backend-error';
import { empty } from '@core/utilities/lang';

type QueryParam = string | number | boolean;
type QueryParams = Record<string, QueryParam | Array<QueryParam>>;

export class Http {
    public static getJson<T>(url: string): Promise<T> {
        return fetch(url)
            .then((response: Response) => {
                if (!response.ok) {
                    return Promise.reject(response);
                }

                return response.json() as Promise<T>;
            })
            .catch((error?: Error | Response) => {
                if (error instanceof Error) {
                    return Promise.reject(error);
                }

                return Promise.reject({
                    ...error, message: error?.statusText || '',
                });
            });
    }

    public static query(obj: QueryParams): string {
        return Object.keys(obj)
            .filter((key: string) => !empty(obj[key]))
            .map((key: string) => {
                const value: QueryParam | Array<QueryParam> = obj[key];

                if (!Array.isArray(value)) {
                    return this.encode(key, value);
                }

                return value.map((value: QueryParam) => this.encode(key, value, true))
                    .join('&');
            })
            .filter((value: string) => !empty(value))
            .join('&');
    }

    public static encode(key: string, value: QueryParam, arrayLike: boolean = false): string {
        return `${ this.encodeKey(key, arrayLike) }${ this.encodeValue(value) }`;
    }

    public static encodeKey(key: string, arrayLike: boolean = false): string {
        return `${ encodeURIComponent(key) }${ arrayLike ? '[]=' : '=' }`;
    }

    public static encodeValue(value: QueryParam): string {
        return encodeURIComponent(value);
    }

    public static getError(response: HttpErrorResponse | IBackendError): IBackendError {
        if (response instanceof HttpErrorResponse) {
            return {
                reason: '',
                code: response.status,
                message: response.statusText,
            };
        }

        return response;
    }
}
