export interface IPagination {
    page: number;

    pages: number;

    limit: number;

    offset: number;
}

export const defaultPagination: IPagination = {
    page: 1,
    pages: 0,
    limit: 15,
    offset: 0,
};
